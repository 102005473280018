<template>
  <div class="spezialisierungen">
    <page-title slug="spezialisierungen" alt="Spezialisierungen">Spezialisierungen</page-title>
    <v-page>
      <div class="grid">
        <div
          class="grid-item"
          v-for="{ titel, beschreibung } in spezialisierungen.slice(
            0,
            Math.min(6, spezialisierungen.length)
          )"
          :key="titel"
        >
          <h2 class="article-title">{{ titel.replace('ß', 'ss') }}</h2>
          <article class="text hyphenate" v-html="beschreibung"></article>
          <mehr-erfahren-link
            v-if="titel == 'Angststörungen'"
            :to="{ name: 'Angststoerungen' }"
          ></mehr-erfahren-link>
        </div>

        <div class="content-img">
          <responsive-image
            :urls="{
              webp: [
                {
                  size: 640,
                  url: require('@/assets/img/spezialisierungen/spezialisierungen_640.webp'),
                },
                {
                  size: 960,
                  url: require('@/assets/img/spezialisierungen/spezialisierungen_960.webp'),
                },
                {
                  size: 1280,
                  url: require('@/assets/img/spezialisierungen/spezialisierungen_1280.webp'),
                },
                {
                  size: 1920,
                  url: require('@/assets/img/spezialisierungen/spezialisierungen_1920.webp'),
                },
              ],
              jpg: [
                {
                  size: 640,
                  url: require('@/assets/img/spezialisierungen/spezialisierungen_640.jpg'),
                },
                {
                  size: 960,
                  url: require('@/assets/img/spezialisierungen/spezialisierungen_960.jpg'),
                },
                {
                  size: 1280,
                  url: require('@/assets/img/spezialisierungen/spezialisierungen_1280.jpg'),
                },
                {
                  size: 1920,
                  url: require('@/assets/img/spezialisierungen/spezialisierungen_1920.jpg'),
                },
              ],
            }"
            sizes="(min-width: 1280px) min(720px, min(calc(100vw - 128px), 1280px)), (min-width: 720px) min(720px, max(80vw, calc(100vw - 128px))), (min-width: 480px) min(960px, max(80vw, calc(100vw - 128px))), 100vw"
            alt="Spezialisierungen"
          ></responsive-image>
        </div>

        <div
          class="grid-item"
          v-for="{ titel, beschreibung } in spezialisierungen.slice(6, spezialisierungen.length)"
          :key="titel"
        >
          <h2 class="article-title">{{ titel.replace('ß', 'ss') }}</h2>
          <article class="text hyphenate" v-html="beschreibung"></article>
        </div>
      </div>
    </v-page>
  </div>
</template>

<script>
import axios from 'axios'

import PageTitle from '@/components/PageTitle'
import VPage from '@/components/Page'

import ResponsiveImage from '@/components/ResponsiveImage'
import MehrErfahrenLink from '@/components/MehrErfahrenLink.vue'

export default {
  name: 'Spezialisierungen',
  components: {
    PageTitle,
    VPage,
    ResponsiveImage,
    MehrErfahrenLink,
  },
  data() {
    return {
      spezialisierungen: [],
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get('/spezialisierungen')
    next(vm => (vm.spezialisierungen = res.data.data))
  },
}
</script>

<style scoped>
@media all and (min-width: 720px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 64px;
    width: var(--text-width);
    margin: 0 auto;
  }

  .grid-item:first-of-type .article-title,
  .grid-item:nth-of-type(2) .article-title {
    margin-top: 0;
  }

  .content-img {
    grid-column: span 2;
  }
}

.text {
  text-align: left;
}
</style>
