var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spezialisierungen"},[_c('page-title',{attrs:{"slug":"spezialisierungen","alt":"Spezialisierungen"}},[_vm._v("Spezialisierungen")]),_c('v-page',[_c('div',{staticClass:"grid"},[_vm._l((_vm.spezialisierungen.slice(
          0,
          Math.min(6, _vm.spezialisierungen.length)
        )),function(ref){
        var titel = ref.titel;
        var beschreibung = ref.beschreibung;
return _c('div',{key:titel,staticClass:"grid-item"},[_c('h2',{staticClass:"article-title"},[_vm._v(_vm._s(titel.replace('ß', 'ss')))]),_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(beschreibung)}}),(titel == 'Angststörungen')?_c('mehr-erfahren-link',{attrs:{"to":{ name: 'Angststoerungen' }}}):_vm._e()],1)}),_c('div',{staticClass:"content-img"},[_c('responsive-image',{attrs:{"urls":{
            webp: [
              {
                size: 640,
                url: require('@/assets/img/spezialisierungen/spezialisierungen_640.webp'),
              },
              {
                size: 960,
                url: require('@/assets/img/spezialisierungen/spezialisierungen_960.webp'),
              },
              {
                size: 1280,
                url: require('@/assets/img/spezialisierungen/spezialisierungen_1280.webp'),
              },
              {
                size: 1920,
                url: require('@/assets/img/spezialisierungen/spezialisierungen_1920.webp'),
              } ],
            jpg: [
              {
                size: 640,
                url: require('@/assets/img/spezialisierungen/spezialisierungen_640.jpg'),
              },
              {
                size: 960,
                url: require('@/assets/img/spezialisierungen/spezialisierungen_960.jpg'),
              },
              {
                size: 1280,
                url: require('@/assets/img/spezialisierungen/spezialisierungen_1280.jpg'),
              },
              {
                size: 1920,
                url: require('@/assets/img/spezialisierungen/spezialisierungen_1920.jpg'),
              } ],
          },"sizes":"(min-width: 1280px) min(720px, min(calc(100vw - 128px), 1280px)), (min-width: 720px) min(720px, max(80vw, calc(100vw - 128px))), (min-width: 480px) min(960px, max(80vw, calc(100vw - 128px))), 100vw","alt":"Spezialisierungen"}})],1),_vm._l((_vm.spezialisierungen.slice(6, _vm.spezialisierungen.length)),function(ref){
          var titel = ref.titel;
          var beschreibung = ref.beschreibung;
return _c('div',{key:titel,staticClass:"grid-item"},[_c('h2',{staticClass:"article-title"},[_vm._v(_vm._s(titel.replace('ß', 'ss')))]),_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(beschreibung)}})])})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }